import React from 'react';
import { Link } from 'react-router-dom';
import { handlerClick } from "../app/app";
import { withTaekwondoServiceContext } from "../hoc";

const HomePage = ( {bodyClick} ) => {
    return(
        <div className="home-page" onClick={bodyClick}>
            <section id="intro" className="intro" data-screen-parallax data-parallax data-offset="15">

                <div className="slogan" data-parallax data-offset="25">
                    <h4>
                        <span className="text_color ua">«Сильним є той, хто перемагає самого себе, а не інших»</span>
                        <span className="text_color ru">«Сильный тот, кто умеет побеждать себя, а не другого»</span>
                    </h4>
                    <h4><span className="ua">Лао Цзи</span><span className="ru">Лао Цзы</span></h4>
                </div>
                <div className="page-scroll shake animated">
                    <Link to='/' data-state="contact" onClick={handlerClick} smooth={'true'} offset={0} duration={1000} className="btn btn-circle">
                        <i className="fa fa-angle-double-down animated"/>
                    </Link>
                </div>
            </section>
            <section id="about" className="home-section text-center">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Друзi клуба "Kyong-Ki"</span>
                                            <span className="ru">Друзья клуба "Kyong-Ki"</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="bounceInUp" data-aos="fade-up" data-aos-delay="200">
                                <div className="team boxed-grey">
                                    <a target="_blank"
                                       href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--4785--%D0%90%D1%80%D0%B1%D1%83%D0%B7%D0%BE%D0%B2.%D0%90%D0%BD%D0%B4%D1%80%D1%96%D0%B9.%D0%92%D0%BE%D0%BB%D0%BE%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87.php">
                                        <div className="inner">
                                            <h5 className="ua">Арбузов Андрій</h5>
                                            <h5 className="ru">Арбузов Андрей</h5>
                                            <p className="subtitle ua">Президент клубу, тренер національного класу, чорный пояс III
                                                дан</p>
                                            <p className="subtitle ru">Президент клуба, тренер национального класса, черный пояс III
                                                дан</p>
                                            <div className="avatar"><img src={require("./img/team/1.jpg")} alt="" className="img-responsive img-circle"/>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bounceInUp" data-aos="fade-up" data-aos-delay="500">
                                <div className="team boxed-grey">
                                    <a target="_blank"
                                       href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--11834--%D0%91%D0%B5%D0%B7%D0%B1%D1%80%D0%B0%D0%B6%D0%BD%D1%8B%D0%B9.%D0%A0%D0%BE%D0%BC%D0%B0%D0%BD.php">
                                        <div className="inner">
                                            <h5 className="ua">Безбражний Роман</h5>
                                            <h5 className="ru">Безбражный Роман</h5>
                                            <p className="subtitle ua">Помічник тренера, чорный пояс III дан</p>
                                            <p className="subtitle ru">Помощник тренера, черный пояс III дан</p>
                                            <div className="avatar"><img src={require("./img/team/3.jpg")} alt="" className="img-responsive img-circle"/>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bounceInUp" data-aos="fade-up" data-aos-delay="800">
                                <div className="team boxed-grey">
                                    <a target="_blank"
                                       href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--4868--%D0%9B%D0%BE%D0%B1%D0%BE%D0%B4%D0%B0.%D0%9A%D0%BE%D0%BD%D1%81%D1%82%D0%B0%D0%BD%D1%82%D0%B8%D0%BD.php">
                                        <div className="inner">
                                            <h5 className="ua">Лобода Костянтин</h5>
                                            <h5 className="ru">Лобода Константин</h5>
                                            <p className="subtitle ua">Помічник тренера, чорный пояс I дан</p>
                                            <p className="subtitle ru">Помощник тренера, черный пояс I дан</p>
                                            <div className="avatar"><img src={require("./img/team/9.jpg")} alt="" className="img-responsive img-circle"/>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bounceInUp" data-aos="fade-up" data-aos-delay="1100">
                                <div className="team boxed-grey">
                                    <a target="_blank"
                                       href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--4869--%D0%9A%D1%80%D0%B8%D0%B2%D1%83%D0%BB%D1%8F.%D0%92%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%B9.php">
                                        <div className="inner">
                                            <h5 className="ua">Кривуля Віталій</h5>
                                            <h5 className="ru">Кривуля Виталий</h5>
                                            <p className="subtitle ua">Помічник тренера, чорный пояс I дан</p>
                                            <p className="subtitle ru">Помощник тренера, черный пояс I дан</p>
                                            <div className="avatar"><img src={require("./img/team/5.jpg")} alt="" className="img-responsive img-circle"/>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bounceInUp" data-aos="fade-up" data-aos-delay="1400">
                                <div className="team boxed-grey">
                                    <a target="_blank"
                                       href="http://itf-ua.org/UA/taekwon-do/%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8--%D0%94%D0%BD%D1%96%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0.%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C--%D0%A3%D1%87%D0%B0%D1%81%D0%BD%D0%B8%D0%BA--13340--%D0%9B%D0%BE%D0%B1%D0%BE%D0%B4%D0%B0.%D0%92%D0%B0%D0%B4%D0%B8%D0%BC.php">
                                        <div className="inner">
                                            <h5 className="ua">Лобода Вадим</h5>
                                            <h5 className="ru">Лобода Вадим</h5>
                                            <p className="subtitle ua">Помічник тренера, чорный пояс I дан</p>
                                            <p className="subtitle ru">Помощник тренера, черный пояс I дан</p>
                                            <div className="avatar"><img src={require("./img/team/2.jpg")} alt="" className="img-responsive img-circle"/>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service" className="home-section text-center bg-gray">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2 className="ua">Наші послуги</h2>
                                        <h2 className="ru">Наши услуги</h2>
                                        <i className="fa fa-2x fa-angle-down"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <Link to="about-club" data-state="page-top" onClick={handlerClick}>
                                <div className="fadeInRight" data-aos="fade-right" data-aos-delay="200">
                                    <div className="service-box">
                                        <div className="service-icon">
                                            <img src={require("./img/icons/dobok.png")} alt=""/>
                                        </div>
                                        <div className="service-desc">
                                            <h5 className="ua">Таеквон-До</h5>
                                            <h5 className="ru">Таэквон-До</h5>
                                            <p className="ua">Традиційне корейське бойове мистецтво. Міжнародна
                                                федерація Таеквон-До. Ця дисципліна за допомогою
                                                тренування тіла і душі прищеплює людині фізичні бойові навички, ставить
                                                за мету поліпшити душевне здоров'я та якість життя.</p>
                                            <p className="ru">Традиционное боевое искусство. Международная федерация Таэквон-До. Эта
                                                дисциплина посредством
                                                тренировки тела и души прививает человеку физические боевые навыки, ставит целью
                                                улучшить душевное
                                                здоровье и качество жизни.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to="tsigun" data-state="page-top" onClick={handlerClick}>
                                <div className="fadeInRight" data-aos="fade-right" data-aos-delay="200">
                                    <div className="service-box">
                                        <div className="service-icon">
                                            <img src={require("./img/icons/icons8-летающий-гуру-48.png")} alt=""/>
                                        </div>
                                        <div className="service-desc">
                                            <h5 className="ua">Цигун</h5>
                                            <h5 className="ru">Цигун</h5>
                                            <p className="ua">Цигун (Таньчжон хоуп) - стародавня корейська оздоровча гімнастика, яка
                                                формувалася
                                                протягом століть і використовується до теперішнього часу як засіб зміцнення здоров'я і
                                                профілактики
                                                різних захворювань, а також як система комплексної регуляції організму.</p>
                                            <p className="ru">Цигун (Таньчжон хоуп) - древняя корейская оздоровительная гимнастика, которая
                                                формировалась на протяжении веков и используется до настоящего времени как средство
                                                укрепления здоровья и профилактики различных заболеваний, а также как система
                                                комплексной
                                                регуляции организма.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to="reabilitation" data-state="page-top" onClick={handlerClick}>
                                <div className="fadeInRight" data-aos="fade-right" data-aos-delay="200">
                                    <div className="service-box">
                                        <div className="service-icon">
                                            <img src={require("./img/icons/icons8-саквояж-врача-48.png")} alt=""/>
                                        </div>
                                        <div className="service-desc">
                                            <h5 className="ua">Реабілітація</h5>
                                            <h5 className="ru">Реабилитация</h5>
                                            <p className="ua">Енергетична та фізична реабілитація. Масаж. Обгортання.</p>
                                            <p className="ru">Энергетическая и физическая реабилитация. Массаж. Обертывания.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to="trainings" data-state="page-top" onClick={handlerClick}>
                                <div className="fadeInRight" data-aos="fade-right" data-aos-delay="200">
                                    <div className="service-box">
                                        <div className="service-icon">
                                            <img src={require("./img/icons/icons8-лиственное-дерево-48.png")} alt=""/>
                                        </div>
                                        <div className="service-desc">
                                            <h5 className="ua">Семінари та тренінги</h5>
                                            <h5 className="ru">Семинары и тренинги</h5>
                                            <p className="ua">Семінари з серії "21 абстрактне ядро"</p>
                                            <p className="ua">"Життя на Дереві" - медитація й практика на платформі, яка розташована на
                                                дереві.
                                                Мотузковий курс —
                                                активний тренінг, тривалістю від одного до чотирьох днів, спрямований на поліпшення
                                                навичок командної
                                                взаємодії, командоутворення.
                                            </p>
                                            <p className="ru">Динамическая медитация. Онлайн, каждую субботу 10:30-12:00</p>
                                            <p className="ru">Цигун. Онлайн, каждое воскресенье 11:00-12:00
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className="home-section text-center">
                <div className="heading-contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2 className="ua">Зв'язатися з нами</h2>
                                        <h2 className="ru">Связаться с нами</h2>
                                        <i className="fa fa-2x fa-angle-down"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row contact-with-us">
                        <div className="col-lg-6">
                            <div className="widget-contact">
{/*                                <div className="contact-items">
                                    <h5 className="ua">Контакти</h5>
                                    <h5 className="ru">Контакты</h5>

                                    <address className="ua">
                                        <strong>Kyong-Ki, club.</strong><br/>
                                        Вул. Шмідта, 3<br/>
                                        Днiпро, 49000<br/>
                                        <abbr title="Phone"/> +380(99)762-51-55
                                    </address>
                                    <address className="ru">
                                        <strong>Kyong-Ki, club.</strong><br/>
                                        Улица Шмидта, 3<br/>
                                        Днепр, 49000<br/>
                                        <abbr title="Phone"/> +380(99)762-51-55
                                    </address>
                                </div>*/}
                                <div className="contact-adress">
                                    <address>
                                        <strong>Email:</strong><br/>
                                        <a href="mailto:#">kyongki.club@gmail.com</a>
                                    </address>
                                    <address>
                                        <strong>We're on social networks:</strong><br/>
                                        <ul className="company-social">
                                            <li className="social-facebook">
                                                <a href="https://www.facebook.com/groups/756171837884873/"
                                                   target="_blank">
                                                    <i className="fa fa-facebook"/>
                                                </a>
                                            </li>
                                            <li className="social-instagram">
                                                <a href="https://www.instagram.com/andriiiarbuzov/"
                                                   target="_blank">
                                                    <i className="fa fa-instagram"/>
                                                </a>
                                            </li>
                                            <li className="social-yt">
                                                <a href="https://www.youtube.com/channel/UCrIfNhuEgEUyk-HaX1BlerA/"
                                                   target="_blank">
                                                    <i className="fa fa-youtube"/>
                                                </a>
                                            </li>
                                            <li className="social-yt">
                                                <a href="https://www.youtube.com/channel/UCOP3xiFYtxT6K0GLtCElPmw/"
                                                   target="_blank">
                                                    <i className="fa fa-youtube"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </address>
                                    <address>
                                        <strong>Parent Federations:</strong><br/>
                                        <ul className="logo-itf-ul">
                                            <li className="logo-itf-li">
                                                <a href="https://www.itf-tkd.org/"
                                                   target="_blank">
                                                    <img src={require("./img/logo-tkd.png")} alt="ITF"/>
                                                </a>
                                            </li>
                                            <li className="logo-itf-li">
                                                <a href="http://www.eitf-taekwondo.org/"
                                                   target="_blank">
                                                    <img src={require("./img/logo-eitf.png")} alt="EITF"/>
                                                </a>
                                            </li>
                                            <li className="logo-itf-li">
                                                <a href="http://itf-ua.org/UA/taekwon-do/index.php"
                                                   target="_blank">
                                                    <img src={require("./img/preview-ukrainian_taekwon-do_federation_itf.png")} alt="Ukraine ITF"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <iframe className="map-google"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1572.8348678164587!2d35.02463596257761!3d48.47221477354951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sua!4v1545858506769"
                                    frameBorder="0" style={{border:'0'}} allowFullScreen />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default withTaekwondoServiceContext(HomePage);
