import React from 'react';
import {bodyClick} from "../app/app";

const Trainings = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2><a name="seminars"></a>
                                            <span className="ua">Семінари та тренінги</span>
                                            <span className="ru">Семинары и тренинги</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-инь-янь-48.png")} alt=""/>
                                        <img src={require("./img/icons/icons8-лиственное-дерево-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">

                                        <b>"Динамическая медитация"</b><br/><br/>
                                        <p>За донат, онлайн, каждую субботу 10:30-12:00<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/trainings/1.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Всем желающим пройти этот курс обращаться в
                                            <a href="https://t.me/floweroflife7" target="_blank"> Telegram</a><br/><br/>
                                            <ul className="company-social">
                                                <li className="social-yt">
                                                    <a href="https://www.youtube.com/channel/UCOP3xiFYtxT6K0GLtCElPmw/"
                                                       target="_blank">
                                                        <i className="fa fa-youtube"/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </p>
                                        <br/><br/>

                                        <b>"Цигун"</b><br/><br/>
                                        <p>За донат, онлайн, каждое воскресенье 11:00-12:00<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/trainings/2.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Всем желающим пройти этот курс обращаться в
                                            <a href="https://t.me/floweroflife7" target="_blank"> Telegram</a><br/><br/>
                                        </p>
                                        <ul className="company-social">
                                            <li className="social-yt">
                                                <a href="https://www.youtube.com/channel/UCOP3xiFYtxT6K0GLtCElPmw/"
                                                   target="_blank">
                                                    <i className="fa fa-youtube"/>
                                                </a>
                                            </li>
                                        </ul>
                                        <br/><br/>

                                        <b>"Раскрытие сверхспособностей человека."</b><br/><br/>
                                        <p>Приглашаю всех желающих на индивидуальный 9 недельный онлайн курс обучения по
                                            цигун "Раскрытие сверхспособностей человека."<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/trainings/Tsigun.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Всем желающим пройти этот курс обращаться в директ
                                            <a href="https://www.instagram.com/andriiiarbuzov/"
                                               target="_blank"> Instagram</a><br/><br/>
                                            "Чтобы покорить Гималаи Духа, необходимо приобрести регулярный опыт на
                                            холмах
                                            и долинах повседневной жизни."<br/>
                                            Ричард Фостер<br/>
                                        </p>
                                        <br/><br/>

                                        <b>"9 брам цигун"</b><br/><br/>
                                        <p>Приглашаю Вас на индивидуальный курс "9 брам цигун".<br/>
                                            Всего 18 часовых индивидуальных практик. Дата начала курса оговаривается
                                            индивидуально.
                                            У Вас в процессе обучения будет три экзамена. В случай хорошей сдачи
                                            экзаменов
                                            по окончанию курса "9 брам цигун." выдаётся сертификат. Добро пожаловать на
                                            индивидуальный курс онлайн или офлайн.<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/trainings/9_bram.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Проводит курс "9 брам цигун" инструктор по физической реабилитации и
                                            лечебной физкультуре
                                            (высшее образование), тренер цигун школы "Единое сердце" с 18-ти летним
                                            стажем, тренер
                                            национального класса по таэквондо, обладатель черного пояса 3-й дан,
                                            массажист-реабилитолог
                                            Арбузов Андрей Владимирович.<br/>
                                        </p>
                                        <br/><br/>

                                        <b>УНИКАЛЬНЫЙ ТЕРАПЕВТИЧЕСКИЙ ТРЕНИНГ "С ШИРОКО ЗАКРЫТЫМИ ГЛАЗАМИ"</b><br/><br/>
                                        <p>Один из самых глубоких по своей силе трансформационных тренингов!<br/><br/>
                                            3,5 часа Вы проведете с закрытыми глазами и познакомитесь с собой настоящим,
                                            без ролей и масок!<br/>
                                            Каждый день мы тратим нашу энергию на внешнюю картинку и разговоры, обращая
                                            ничтожно мало внимания
                                            на свой внутренний мир.<br/><br/>
                                            Практики с закрытыми глазами - великолепная возможность через движения,
                                            музыку,
                                            нестандартные техники взаимодействия с собой и другими людьми:<br/>
                                            ✔ снять стресс, проработать тревогу и страхи;<br/>
                                            ✔ отказаться от оценочного мышления и привычки чрезмерного контроля;<br/>
                                            ✔ исследовать свои бессознательные убеждения;<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/trainings/Trening.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Проводит тренинг "С широко закрытыми глазами." Андрей Арбузов - мастер
                                            цигун,
                                            автор тренинговых программ, координатор ТЦ "Flower of life", президент клуба
                                            Таеквон-До "Kyong-Ki."<br/><br/>
                                            Отзыв Оли, участницы тренинга "С широко закрытыми глазами.":<br/>
                                            "После тренинга: "С широко закрытыми глазами." мир обретает новые краски.
                                            Когда я открылась чувствам, я стала ощущать свежесть и радость. И это начало
                                            пути!
                                            Я поняла, что с течением времени можно обрести навык управлять своими
                                            чувствами,
                                            научиться выражать их словами, через творчество. Но самое главное - я
                                            поняла,
                                            как легко быть счастливой. Чувствовать счастье невозможно, если блокируешь
                                            свою
                                            способность чувствовать разнообразные проявления мира. На тренинге я
                                            раскрепостилась,
                                            вошла в состоянии Доверии и Присутствия, где счастье не зависит от Чувств и
                                            Мыслей,
                                            а Счастье – это способ жить!"
                                            <br/><br/>
                                            Ещё один отзыв участника тренинга "С широко закрытыми глазами.":<br/>
                                            "Большое спасибо за тренинг "С широко закрытыми глазами." Такое ощущение,
                                            что
                                            летал по небу и видел всем телом, что и где находится, ощущал тепло от
                                            людей,
                                            идущих рядом со мной. А ещё развил в себе смелость! Я шёл по ощущениям и не
                                            врал себе, а доверял своему внутреннему Я! Я открылся для восприятия всего
                                            окружающего мира, как ребенок! "
                                            <br/><br/>
                                            "Приключение с тренером Андреем Арбузовым на тренинге "С широко закрытыми
                                            Глазами!"
                                            сложно описать словами...<br/>
                                            Ты закрываешь глаза. И начинаешь видеть гораздо больше... Тренер Андрей
                                            чуткий проводник,
                                            он помогает, направляет уверенно... Так что ты действительно начинаешь
                                            видеть, доверять,
                                            всему что происходит вокруг. Доверять Земле под ногами, с её неровностями,
                                            доверять
                                            своим ногам... а потом момент - и ты в доверии ко всему миру, идёшь с
                                            закрытыми
                                            глазами и широкой улыбкой, от того, что это так радостно!.. Это так весело,
                                            легко,
                                            и в то же время глубоко. Мы гуляли - наблюдая мир Закрытыми Глазами, я
                                            добавляла
                                            ему красок, которых как мне казалось не хватало, ощущала людей вокруг, с
                                            закрытыми
                                            глазами гораздо лучше начинаешь видеть сердцем..."
                                            <br/>
                                        </p>
                                        <br/><br/>

                                        <b>Семинары из серии "21 абстрактное ядро":</b><br/><br/>
                                        1."Внимание - путь к абсолютной свободе восприятия."<br/>

                                        Осознанность. Сила настоящего момента.
                                        Упражнения для осознанности.
                                        Большинство людей живут в состоянии постоянного недовольства, беспокойства,
                                        тревоги, страха. Это какой-то замкнутый круг, где за пеленой вечной
                                        неудовлетворенности просто не видно двери, ведущей к счастью. Однако эта дверь
                                        существует прямо здесь и сейчас. На эту активную каждодневную работу и
                                        настраивает этот семинар.
                                        Внимание это упорядоченное и усиленно процессом жизни осознание.
                                        Умение быть внимательным позволяет всему фрагментарному и конфликтующему в нас
                                        успокоиться, открывая нашу истинную природу. Это приведёт к глубоким переменам в
                                        жизни человека, поможет перенести практики в повседневную жизнь.
                                        Постоянное осознавание времени может удлинить жизнь человека до нескольких сот
                                        лет. Осознавание времени — это особое состояние восприятия, в котором процесс
                                        старения приостанавливается.
                                        Выполняя энергетические упражнения, мы научимся использовать помехи, которые у
                                        нас идут изнутри,
                                        как источник энергии для самого процесса осознавания. Мы научимся извлекать
                                        энергию из усталости и сонливости, находясь в ясном состоянии
                                        осознания.<br/><br/>


                                        2."Северный, южный, западный, восточный ветер. Четыре энергетических
                                        конфигурации людей."<br/>

                                        Какая твоя энергетическая конфигурация? Энергетические практики, направленные на
                                        осознавание своей природы.<br/><br/>

                                        3."Эволюция - от одноклеточного до квантового человека"<br/>
                                        Проживи, прочувствуй с помощью упражнений эволюцию!<br/><br/>

                                        4."Карта тела 1. Исследование своего тела."<br/>
                                        Основы тайского массажа.<br/><br/>

                                        5."Карта тела 2. Исследование своего тела." Основы классического массажа и
                                        авторской программы "Счастливое тело".<br/><br/>

                                        6."Карта тела 3. Основы эротического массажа". Принятие своей творческой
                                        энергии.<br/><br/>

                                        7. "Будь целостным. Собери себя сам. Как сбросить балласт Эго."<br/>
                                        ✓ Какие есть семь основопологаюших принципов жизни?<br/>
                                        ✓ Какие сопротивления являются источником напряжения? Как эти напряжения
                                        вызывают болезни.<br/>
                                        ✓ Как выявить свои шаблоны, гештальты, импринты? Как трансформировать негативную
                                        энергию?<br/>
                                        ✓ Как используя семь принципов сталкинга стать творцом своей реальности?<br/>
                                        ✓ Как ЭГО разделяет нас, не позволяя слышать друг друга.<br/>
                                        ✓ Три ЭГО - состояния, взаимодействие которых определяют наше поведение.
                                        Построение ЭГОграммы.<br/>
                                        ✓ Чистка энергетического канала отношений на примере одного близкого человека, с
                                        которым есть желание улучшить отношения.<br/>
                                        ✓ Изучим базовые элементы целительства.<br/>
                                        Изучим элементы омолаживания организма, повысим иммунитет и укрепим
                                        здоровье.<br/>
                                        ✓На тренинге будем выполнять цигун гимнастику, дыхательные упражнения и другие
                                        разнообразные упражнения и практики.<br/><br/>

                                        8."Контролируемые осознанные сновидения."<br/>
                                        Факт вхождения в осознанное сновидение - это всегда революционное
                                        психологическое событие, радикально меняющее мировоззрение человека. Ощущение
                                        себя свидетелем собственного пробуждения во сне есть открытие в сознании новых
                                        измерений альтернативной реальности. При этом возможность жить во сне не
                                        является приобретением или плодом усилий, а изначально присуща природе
                                        человеческого восприятия.
                                        Первым этапом практики осознанных сновидений является стабилизация картины
                                        сновидения.
                                        Вторым этапом практики управления сном является созерцание уже нескольких
                                        произвольных объектов путём поочерёдного перемещения внимания между ними.
                                        Третий этап тренировок направлен на синхронизацию времени сна и бодрствования.
                                        Также будут рассмотрены и следующие этапы практики.<br/><br/>

                                        9."Микрокосмическая орбита.Открытие меридианов."<br/>
                                        Основы медитативных техник. Защита от энергетического воздействия. Укрепление
                                        здоровья.<br/><br/>

                                        10."Тайцзи - здоровье, гармония, баланс."<br/>
                                        Умение делать направленный выброс энергии. Элементы комплекса Пао-чуй.<br/><br/>

                                        11."Долинный оргазм. Работа с сексуальной энергией."<br/>
                                        Мужская и женская сексуальная энергия. Как мужчине и женщине получать много
                                        оргазмов за одну ночь.<br/><br/>

                                        12." Тотемное животное".<br/>
                                        Осознание энергетического тела. Работа с энергией Рода.<br/><br/>

                                        13."Моделирование будущего. Намерение. Билет в безупречность."<br/>
                                        Как формировать намерение. Техники моделирования будущего.<br/><br/>

                                        14."Омоложение и оздоровление организма."<br/>
                                        Разнообразные техники, направленные
                                        на омоложение и оздоровления организма.<br/><br/>


                                        15."Чжан чжуан. Столбовое стояние." Здоровье и сила. Слияние внутреннего и
                                        внешнего цигун ( работа с энергией).<br/><br/>

                                        16."Таеквон-до - путь воина."<br/>
                                        Таеквондо с нуля. Базовая техника боевого Таеквон-до. Закалка Духа.<br/><br/>

                                        17."Классический цигун."<br/>
                                        Укрепление здоровья. 8 отрезов парчи. Игры пяти животных. Ицзин цзин. Укрепление
                                        костей и сухожилий.<br/><br/>

                                        18."Создание золотой таблетки Земли"<br/>
                                        Мы освоим уникальный комплекс по созданию эликсира жизни - это основное
                                        преобразующее вещество в высшей духовной работе,
                                        способное избавить от любых болезней.<br/><br/>


                                        19."Безмолвное знание."<br/>
                                        Техник по остановке внутреннего диалога.
                                        Глубинные медитативные практики. Перераспределение рассеянной энергии при помощи
                                        рук и ног.<br/><br/>

                                        20."Осознание женской природы." Энергетические комплексы по активизации матки,
                                        как камеры восприятия. Активизация своей женской природы.<br/><br/>


                                        21."Практики по улучшению зрения."<br/>
                                        Медитации, практики и техники , представленные на семинаре, не являются
                                        альтернативой профессиональному медицинскому лечению и не заменяют его.
                                        Откройте для себя истинные секреты отличного зрения без очков.<br/><br/>
                                        <img className="img-content" src={require("./img/Tsigun_Trener.jpg")}
                                             style={{width: "80%"}}/><br/><br/>

                                        <b>Практика в безмолвии "Жизнь на Дереве":</b><br/><br/>
                                        <p>Приглашаем Вас на практику в безмолвии "Жизнь на Дереве".<br/>

                                            Три дня жизни в уединении и безмолвии на деревянной платформе (с тентом от
                                            дождя и
                                            антимоскитной сеткой и всем необходимым страховочным оборудованием и био
                                            туалетом)
                                            на дереве (максимальное время практики - до 9 суток).<br/>

                                            Цель практики: оздоровление, осознание своей истиной природы, возможность
                                            уникального продвижения в практиках медитаций, созерцания, цигун и
                                            перепросмотра
                                            своей жизни.<br/>

                                            Время проведения: с апреля по октября 2020 года.
                                            Бронировать места необходимо заблаговременно.
                                            До Нового года стоимость сертификата составляет 100 евро на трое суток
                                            практики!
                                            После Нового года стоимость такой практики - 150 евро. Время вашей практики
                                            Вы
                                            бронируете заранее.
                                            Осенняя практика: осенью накопленную энергию летнего цикла жизни дерево
                                            направляет к
                                            корням, чтобы сформировать намерение следующего весеннего пробуждения.<br/>
                                            Летняя практика: летом дерево обновляет свою структуру в полном
                                            взаимодействии с
                                            Солнцем, звездами, воздухом, Землёй, используя энергию Вселенной для
                                            расширения восприятия.<br/>
                                            <br/>
                                            <img className="img-content"
                                                 src={require("./img/43317206_1827983157285096_1061070004029489152_o.jpg")}
                                                 style={{width: "80%"}}/><br/>
                                            Весенняя практика: весна - это пробуждение дерева, дерево наполняется мощным
                                            восходящим потоком. Этот поток - это рождение нового, поток мощного
                                            очищения,
                                            восстановления и оздоровления.<br/>
                                            Практика "Жизнь на Дереве" - это жизнь в полной гармонии с природой и
                                            Вселенной, с
                                            ее естественными биоритмами, помогающая раскрыть полностью потенциал
                                            человека. Это
                                            проработка эмоций, наполнение новыми эмоциями радости, любви, единения с
                                            окружающим
                                            миром.<br/>
                                            Мир современного человека техногенной цивилизации отделяет его от основных
                                            источников его силы. "Обыватель" - это стало определением человека
                                            современной
                                            эпохи. Человек превратился в биоробота не знающего, не чувствующего, не
                                            ощущающего,
                                            не осознающего своей миссии Человека, своего предназначения.<br/>
                                            Деревья- это древние существа, пришедшие на Землю задолго до человека.
                                            Лишенные
                                            человеческих заблуждений, они являются примером первозданной и эффективной
                                            структурой энергетического взаимодействия. В их телах хранятся знание о
                                            гармоничной
                                            структуре человеческого организма и естественных огромных истинных
                                            возможностях
                                            человека. Весной взаимодействие человека и дерева усиливается естественным
                                            образом,
                                            благодаря бурному пробуждению дерева.<br/>
                                            Это мощнейшая проработка эго, потому что, "подружившись" с деревом, которое
                                            живёт в
                                            гармонии со всем миром, человек уже не сможет чувствовать себя отчужденным
                                            от
                                            Вселенной.<br/>

                                            Проживание:<br/>
                                            платформа на дереве в живописном месте в лесу, палаточный лагерь, который
                                            обеспечивает питание, под открытым небом.<br/>
                                            Вас обеспечат безопасной конструкцией с защитой от дождя и ветра, страховкой
                                            для
                                            вашей безопасности.<br/>

                                            Снаряжение индивидуальное: туристический коврик, спальник, соответствующая
                                            сезону
                                            одежда.<br/>

                                            Питание:приготовление еды на костре на выбор в лагере с доставкой на дерево.<br/>
                                            Необходима предварительная регистрация и предоплата. После предоплаты Вам
                                            будет
                                            проведён инструктаж. После приезда в базовый лагерь все вопросы решают
                                            инструкторы
                                            по проведению практики.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Trainings;